.scroll {
  --var1: transparent; /* Initial color for the scrollbar thumb */
  --var2: transparent; /* Initial color for the scrollbar background */
  transition: all 0.5s ease-in-out; /* Apply transition to all properties */
}

.scroll:hover {
  --var1: #484c9c;
  --var2: #ffffff;
}

.scroll::-webkit-scrollbar {
  background: var(--var2);
  
  width: 8px;
  height: 8px;
}

.scroll::-webkit-scrollbar-thumb {
  background: var(--var1);
  border-radius: 8px;
  transition: background 0.5s; /* Apply transition to the background color */
}


.scroll2::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #ffffff;
}

.scroll2::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

.scroll2::-webkit-scrollbar-thumb
{
	border-radius: 8px;
	background-color: #484c9c;
}