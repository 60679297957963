.app-footer {
    text-align: center;
    font-size: 14px;
    margin-top: 0; /* 30px */
  }
  
  .app-footer ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
  .app-footer li {
    display: inline;
    margin-right: 15px;
  }
  
  .app-footer a {
    color: #333;
    text-decoration: none;
  }
  
  .app-footer a:hover {
    text-decoration: underline;
  }
  